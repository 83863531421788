<template>
  <v-row class="invite-row" no-gutters>
    <v-col cols="12" md="6" order="last" order-md="first">
      <div
        class="d-flex flex-column align-center justify-center"
        :class="checkMd ? 'image-left' : 'image-bottom'"
      >
        <div class="my-auto"></div>
        <v-icon class="mt-auto" :size="checkSm ? 325 : 180">$logoIcon</v-icon>
        <div class="d-flex my-auto pb-10">
          <v-icon class="mr-10" @click="goToLinkedin">$linkedinkIcon</v-icon>
          <v-icon @click="goToFacebook">$facebookIcon</v-icon>
        </div>
      </div>
    </v-col>
    <v-col class="d-flex justify-center align-center" cols="12" md="6" order="first" order-md="last">
      <v-card
        flat
        class="inviteCardBg invite-connect"
        :class="isXs ? 'pa-15 pa-sm-20' : 'pa-8'"
        :max-width="isMobile ? '550' : '90%'"
      >
        <h2 class="font-logo-xs inviteTitle--text py-10">{{ infoMessage }}</h2>
        
        <div class="d-flex justify-end">
          <ActionOutlinedButton
            :to="{ name: 'AuthLogin' }"
            textColor="inviteFieldBorder--text"
            text
          >Log in</ActionOutlinedButton>
        </div>
      </v-card>
    </v-col>
  </v-row>
</template>

<script>
import ActionOutlinedButton from '@/components/buttons/ActionOutlinedButton'

import { checkRegisterToken } from '@/api/auth-routes'

export default {
  name: 'RegisterConfirmation',
  components: { ActionOutlinedButton },
  props: {
    token: {
      type: String | Number,
      default: ''
    },
  },
  data: () => ({
    infoMessage: null,
  }),
  computed: {
    isMobile() {
      return this.$vuetify.breakpoint.width > 600
    },
    isXs() {
      return this.$vuetify.breakpoint.width > 360
    },
    checkMd() {
      return this.$vuetify.breakpoint.width > 1029
    },
    checkSm() {
      return this.$vuetify.breakpoint.width > 599
    },
  },
  methods: {
    async loadRegisterConfirmationToken(token) {
      const { message } = await checkRegisterToken(token)
      
      if (message) {
        this.infoMessage = message
      }
    },
    goToFacebook() {
      window.open('https://www.facebook.com/Dialics-109364704630086', '_blank')
    },
    goToLinkedin() {
      window.open('https://www.linkedin.com/company/dialics', '_blank')
    },
  },
  watch: {
    token: {
      immediate: true,
      handler(token) {
        if (!!token) {
          this.loadRegisterConfirmationToken(token)
        } else {
          this.$router.push({ name: 'AuthLogin' })
        }
      }
    },
  },
}
</script>

<style lang="scss">
  .invite-row {
    height: 100%;
    
    .image-left {
      height: 100%;
      background-image: url(/images/invite_bg.png);
      background-position: right;
    }
    .image-bottom {
      height: 100%;
      background-image: url(/images/login_bottom_bg.png);
      background-position: top;
    }
  }
</style>
